import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || "https://bumili.lv";

async function requestNewToken(setIsAdmin) {
    try {
        const response = await axios.get(baseURL + "/api/admin/getAdmin");
        const token = response.data.token;
        localStorage.setItem('jwt', token);
        setIsAdmin(true); // Set isAdmin based on response
    } catch (error) {
        console.error('Error obtaining new token:', error.response ? error.response.data : error.message);
        setIsAdmin(false); // Set isAdmin to false if there's an error
    }
}

export default requestNewToken;
