import React from 'react';
import './Loading.class.css';  // Import the CSS file

function Loading() {
    return (
        <div className="loading-container">
            <h1>Loading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h1>
        </div>
    );
}

export default Loading;
