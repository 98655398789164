import React, { useEffect, useState } from 'react';
import './App.css';
import UnderDevelopment from './pages/UnderDevelopment';
import Loading from './components/Loading';
import {jwtDecode} from 'jwt-decode';
import requestNewToken from './http/users';

function App() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        async function checkJwt() {
            const token = localStorage.getItem('jwt');

            if (token) {
                if (isTokenExpired(token)) {
                    console.log('Token has expired');
                    localStorage.removeItem('jwt');
                    await requestNewToken(setIsAdmin);
                } else {
                    console.log('Token is valid');
                    setIsAdmin(true);
                }
            } else {
                console.log('No token found, requesting a new one');
                await requestNewToken(setIsAdmin);  // Pass setIsAdmin to requestNewToken
            }

            setLoading(false); // Stop loading after checking token
        }

        function isTokenExpired(token) {
            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Current time in seconds
                return decoded.exp < currentTime;
            } catch (error) {
                return true; // If decoding fails, assume the token is expired
            }
        }

        checkJwt();
    }, []);

    // Display loading screen while checking JWT
    if (loading) {
        return <Loading />;
    }

    return (
        <div className="App">
            {isAdmin ? <h1>Hi Admin</h1> : <UnderDevelopment />}
        </div>
    );
}

export default App;
