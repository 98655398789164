import React from "react";
import "./UnderDevelopment.class.css";

function UnderDevelopment() {
    return (
        <div className="UnderDevelopment">
            <h1>BUMILI</h1>
            <p>šobrīd mājaslapa atrodas izstrādes stadijā. <br /> uz Drīzu tikšanos <br />BUMILI</p>
            <div className="loader"></div>
        </div>
    );
}

export default UnderDevelopment;